import React from 'react'
import { Mdx } from 'common/types'
import BlogItem from './blog-item'

type Props = {
  items: ReadonlyArray<{
    node: Mdx
  }>
}

const Posts = ({ items }: Props) => {
  return (
    <ul>
      {items.map(({ node }) => (
        <BlogItem key={node.fields.slug} node={node} />
      ))}
    </ul>
  )
}

export default Posts
