import { Category } from 'common/types'
import { Link } from 'gatsby'
import styled from 'styled-components'
import theme from 'styles/theme'

const colors: { [K in Category]: string } = {
  tech: theme.color.text,
  books: theme.color.accent.lightBlue,
  video: theme.color.accent.orange,
}

export const Wrapper = styled(Link)<{ category: Category }>`
  display: block;
  color: ${theme.color.text};
  margin-bottom: 2rem;
  transition: color 0.2s ease-in-out;
  cursor: pointer;
  text-decoration: none;
  display: grid;
  grid-template-columns: 7rem 1fr;
  gap: 2rem;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    color: ${theme.color.accent.blue};
  }
`

export const Timestamp = styled.small`
  color: ${theme.color.muted};
  text-align: right;
`

export const Title = styled.h3`
  margin: 0;
  color: inherit;
`
