import React, { FC } from 'react'

import { Mdx } from 'common/types'
import { Wrapper, Title, Timestamp } from './styles'

const BlogItem: FC<{ node: Mdx }> = ({ node }) => (
  <Wrapper to={node.fields.slug} category={node.frontmatter.category}>
    <Timestamp>{node.frontmatter.date}</Timestamp>
    <Title>{node.frontmatter.title || node.fields.slug}</Title>
  </Wrapper>
)

export default BlogItem
