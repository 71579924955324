import React from 'react'
import { Mdx } from 'common/types'
import Layout from 'components/layout'
import SEO from 'components/seo'
import Posts from 'components/posts'

type Props = {
  data: {
    allMdx: {
      edges: ReadonlyArray<{
        node: Mdx
      }>
    }
  }
}

const Home = ({ data }: Props) => (
  <Layout>
    <SEO
      title="All posts"
      keywords={[`blog`, `gatsby`, `javascript`, `react`]}
    />
    <Posts items={data.allMdx.edges} />
  </Layout>
)

export default Home
