import { graphql } from 'gatsby'
import Blog from 'modules/blog'

export default Blog

export const pageQuery = graphql`
  query {
    allMdx(
      filter: { frontmatter: { tags: { nin: ["standalone"] } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMM YYYY")
            title
            description
            category
          }
        }
      }
    }
  }
`
